<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4" v-if="user">
      <va-card-title style="font-size: 1.5rem;">My Profile</va-card-title>
      <va-card-title style="font-size: 1rem; margin-top: -1rem; color: #707070;">{{ user.email }}</va-card-title>
      <va-card-content>
        <form @submit.prevent="onsubmit">
          <va-input
            class="mb-3"
            v-model="user.first_name"
            type="text"
            label="First Name"
            :error="!!firstNameErrors.length"
            :error-messages="firstNameErrors"
          />

          <va-input
            class="mb-3"
            v-model="user.last_name"
            type="text"
            label="Last Name"
            :error="!!lastNameErrors.length"
            :error-messages="lastNameErrors"
          />

          <va-select
            class="mb-3"
            label="Access Level"
            v-model="user.access"
            :options="accessOptions"
          />

          <va-input
            class="mb-3"
            v-model="user.password"
            type="password"
            label="Password"
          />

          <div class="d-flex justify--center mt-3">
            <va-button @click="save" class="my-0">Save</va-button>
          </div>
        </form>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load profile data
      const user = await axios.get(`${process.env.VUE_APP_API_URL}/api/profile`, { headers })
      this.user = user.data.user
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/profile' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      user: null,
      accessOptions: ['Administrator', 'Manager', 'User'],
      firstNameErrors: [],
      lastNameErrors: []
    }
  },
  methods: {
    save: async function () {
      this.loader = this.$loading.show()
      try {
        // Handle errors
        this.firstNameErrors = []
        this.lastNameErrors = []
        if (!this.user.first_name || this.user.first_name.length <= 0) {
          this.firstNameErrors.push('Enter valid First Name')
        }
        if (!this.user.last_name || this.user.last_name.length <= 0) {
          this.lastNameErrors.push('Enter valid Last Name')
        }

        // Check errors first
        if (this.firstNameErrors.length <= 0 && this.lastNameErrors.length <= 0) {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          const payload = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            access: this.user.access
          }

          // Use password if password is set
          if (this.user.password && this.user.password.length > 0) {
            payload.password = this.user.password
          }
          const user = await axios.put(`${process.env.VUE_APP_API_URL}/api/profile`, payload, { headers })
          this.user = user.data.user

          // Show success message
          this.$vaToast.init({
            message: 'User saved successfully!',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'primary',
          })
        } else {
          // Show error message
          this.$vaToast.init({
            message: 'Error on user save!',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'danger',
          })
        }
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: '/pages/profile' } })
      }
      this.loader.hide()
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }
</style>
